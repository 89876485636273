<template>
  <v-card flat height="100%" class="rounded-lg">
    <v-card-text>
      <v-date-picker v-model="picker" full-width></v-date-picker>
      <v-text-field
        v-model="search"
        label="Search..."
        dense
        filled
        clearable
        class="rounded-lg"
      />
      <div>
        <v-checkbox
          v-for="(tag, key) in tags"
          :key="key"
          v-model="selectedTags"
          :label="tag.text"
          :value="tag.value"
          hide-details
        ></v-checkbox>
      </div>
    </v-card-text>
    <v-card-actions class="mx-3 mb-4">
      <v-spacer></v-spacer>
      <v-btn class="bg-secondary text-color-white" @click="filter()">
        Filter
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { EVENT_CALENDAR_COMPONENT_EVENT_BUS } from "./js/eventCalendarComponentEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const locationRepository = RepositoryFactory.get("location");

export default {
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    search: null,
    // selectedTags: ["event", "conference", "birthday", "anniversary"],
    // tags: [
    //   { text: "Events", value: "event" },
    //   { text: "Conferences", value: "conference" },
    //   { text: "Birthdays", value: "birthday" },
    //   { text: "Anniversaries", value: "anniversary" }
    // ]
    selectedTags: [],
    tags: []
  }),
  methods: {
    filter() {
      let notSelectedTags = this.tags.filter(
        item => !this.selectedTags.includes(item.value)
      );

      let params = {
        search: this.search,
        notSelectedTags: notSelectedTags.map(item => {
          return item.value;
        })
      };

      EVENT_CALENDAR_COMPONENT_EVENT_BUS.$emit("filterCalendarEvents", params);
    },
    getLocations() {
      let params = {
        full_data: true
      };

      locationRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          this.tags = items.map(item => {
            this.selectedTags.push(item.id);

            return {
              text: item.name,
              value: item.id
            };
          });
        })
        .catch(() => {
          this.tags = [];
        });
    }
  },
  mounted() {
    this.getLocations();
  }
};
</script>
